import React from "react"

const OkrugleCevi = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Isporučuju se prema standardu SRPS EN 10219-1 šavne cevi, hladno
          oblikovane, okruglog, kvadratnog i pravougaonog preseka za
          konstrukcije, oblik i mere u standardu SRPS EN 10219-2 čelicne cevi sa
          šavom, oblik i mere - bez hidroispitivanja u dimenzijama: -
          spoljašnjeg prečnika od 17,2 - 711 mm i debljine zida 1,5 - 12,5 mm
          prema tabeli: Okrugle cevi se isporučuju takođe prema standardu SRPS
          EN 10219-2 i SRPS EN 10255 cevi od čelika bez propisanih mehaničkih
          osobina, za cevni navoj i mere - hidroispitane
        </p>
      </div>
      <div className="table-scroll">
        <table className="table is-bordered is-hoverable">
          <tbody>
            <tr>
              <th rowSpan="3" className="has-text-centered">
                <br />
                Spoljašnji prečnik (mm)
              </th>
              <th colSpan={15} className="has-text-centered is-link">
                Debljina zida (mm)
              </th>
            </tr>
            <tr>
              <td>1,5</td>
              <td>1,8</td>
              <td>2</td>
              <td>2,5</td>
              <td>2,6</td>
              <td>3</td>
              <td>3,25</td>
              <td>3,5</td>
              <td>4</td>
              <td>4,5</td>
              <td>5</td>
              <td>5,6</td>
              <td>6,3</td>
              <td>7,1</td>
              <td>8</td>
            </tr>

            <tr>
              <th colSpan={15} className="has-text-centered is-link">
                Produžena masa (kg/m)*
              </th>
            </tr>
            <tr>
              <td>17,20</td>
              <td>0,58</td>
              <td>0,68</td>
              <td>0,75</td>
              <td>0,91</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>21,30</td>
              <td>0,73</td>
              <td>0,86</td>
              <td>0,95</td>
              <td>1,16</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>25,40</td>
              <td>0,88</td>
              <td>1,05</td>
              <td>1,15</td>
              <td>1,41</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>26,90</td>
              <td>0,94</td>
              <td>1,11</td>
              <td>1,23</td>
              <td>1,50</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>31,80</td>
              <td>1,12</td>
              <td>1,33</td>
              <td>1,47</td>
              <td>1,81</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>33,70</td>
              <td>1,19</td>
              <td>1,42</td>
              <td>1,56</td>
              <td>1,92</td>
              <td>1,99</td>
              <td>2,27</td>
              <td>2,44</td>
              <td>2,61</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>38,00</td>
              <td>1,35</td>
              <td>1,61</td>
              <td>1,78</td>
              <td>2,19</td>
              <td>2,36</td>
              <td>2,59</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>40,00</td>
              <td>1,42</td>
              <td>1,69</td>
              <td>1,87</td>
              <td>2,31</td>
              <td>2,52</td>
              <td>2,74</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>42,40</td>
              <td>1,51</td>
              <td>1,80</td>
              <td>1,99</td>
              <td>2,46</td>
              <td>2,55</td>
              <td>2,91</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>44,50</td>
              <td>1,59</td>
              <td>1,90</td>
              <td>2,10</td>
              <td>2,59</td>
              <td>2,84</td>
              <td>3,07</td>
              <td>3,58</td>
              <td>3,84</td>
              <td>4,38</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>48,30</td>
              <td>1,73</td>
              <td>2,06</td>
              <td>2,28</td>
              <td>2,82</td>
              <td>3,02</td>
              <td>3,35</td>
              <td>3,61</td>
              <td>4,1</td>
              <td>4,37</td>
              <td>5,25</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>51</td>
              <td>1,83</td>
              <td>2,18</td>
              <td>2,42</td>
              <td>2,99</td>
              <td>3,2</td>
              <td>3,55</td>
              <td>3,83</td>
              <td>4,4</td>
              <td>4,85</td>
              <td>5,44</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>56</td>
              <td>&nbsp;</td>
              <td>2,40</td>
              <td>2,66</td>
              <td>3,33</td>
              <td>3,65</td>
              <td>3,92</td>
              <td>4,23</td>
              <td>4,53</td>
              <td>5,49</td>
              <td>6,14</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60,30</td>
              <td>&nbsp;</td>
              <td>2,60</td>
              <td>2,88</td>
              <td>3,56</td>
              <td>3,85</td>
              <td>4,24</td>
              <td>4,57</td>
              <td>4,9</td>
              <td>5,55</td>
              <td>6,19</td>
              <td>6,82</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>63,25</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>3,02</td>
              <td>3,74</td>
              <td>4,06</td>
              <td>4,66</td>
              <td>4,81</td>
              <td>5,41</td>
              <td>6,18</td>
              <td>6,92</td>
              <td>7,65</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>76,1</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>3,65</td>
              <td>4,54</td>
              <td>4,85</td>
              <td>5,41</td>
              <td>5,84</td>
              <td>6,26</td>
              <td>7,11</td>
              <td>7,95</td>
              <td>8,87</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>88,9</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4,29</td>
              <td>5,32</td>
              <td>5,53</td>
              <td>6,35</td>
              <td>6,86</td>
              <td>7,37</td>
              <td>8,38</td>
              <td>9,37</td>
              <td>10,3</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>101,6</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>6,11</td>
              <td>6,53</td>
              <td>7,29</td>
              <td>7,88</td>
              <td>8,46</td>
              <td>9,63</td>
              <td>10,8</td>
              <td>11,9</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>108</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>6,61</td>
              <td>6,87</td>
              <td>7,76</td>
              <td>8,39</td>
              <td>9,02</td>
              <td>10,3</td>
              <td>11,5</td>
              <td>12,7</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>114,3</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>7,34</td>
              <td>8,23</td>
              <td>8,9</td>
              <td>9,56</td>
              <td>10,9</td>
              <td>12,2</td>
              <td>13,5</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>127</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>8,14</td>
              <td>9,17</td>
              <td>10,15</td>
              <td>10,65</td>
              <td>12,10</td>
              <td>13,97</td>
              <td>15</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>133</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>9,61</td>
              <td>10,39</td>
              <td>11,17</td>
              <td>12,7</td>
              <td>14,3</td>
              <td>15,8</td>
              <td>17,6</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>139,7</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>13,4</td>
              <td>15</td>
              <td>16,6</td>
              <td>18,5</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>159</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>15,3</td>
              <td>17,1</td>
              <td>19</td>
              <td>21,2</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>168,3</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>16,2</td>
              <td>18,2</td>
              <td>20,1</td>
              <td>22,5</td>
              <td>25,2</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>219,1</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>26,4</td>
              <td>29,5</td>
              <td>33,1</td>
              <td>37,1</td>
              <td>41,6</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OkrugleCevi
