import React from "react"

const PravougaoneCevi = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Isporučuju se prema standardu SRPS EN 10219-1 šavne cevi, hladno
          oblikovane, okruglog, kvadratnog i pravougaonog preseka za
          konstrukcije, oblik i mere u dimenzijama:
          <br /> - stranice pravougaonog od 20x10 mm i debljine zida 1,5 - 6 mm
          prema tabeli
          <br />
          Proizvodna dužina za okrugle, kvadratne i pravougaone cevi dimenzije
          do 40x40 mm je od 4m do 8m, a za dimenzije preko 50x50 mm je od 4m do
          12m.
          <br />
          Po zahtevu je moguće sečenje po meri.
        </p>
      </div>
      <div className="table-scroll">
        <table className="table is-bordered is-hoverable is-fullwidth">
          <tbody>
            <tr>
              <th rowSpan="3" className="has-text-centered">
                <br />
                <br />
                Dimezije stranica (mm)
              </th>
              <th colSpan={15} className="has-text-centered is-link">
                Debljina zida (mm)
              </th>
            </tr>
            <tr>
              <td>1,5</td>
              <td>1,7</td>
              <td>1,8</td>
              <td>2</td>
              <td>2,5</td>
              <td>3</td>
              <td>3,5</td>
              <td>4</td>
              <td>4,5</td>
              <td>5</td>
              <td>6</td>
            </tr>

            <tr>
              <th colSpan={15} className="has-text-centered is-link">
                Produžena masa (kg/m)*
              </th>
            </tr>
            <tr>
              <td>20x10</td>
              <td>0,71</td>
              <td>0,81</td>
              <td>0,85</td>
              <td>0,789</td>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>30x20</td>
              <td>1,07</td>
              <td>1,24</td>
              <td>1,31</td>
              <td>1,45</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>30x15</td>
              <td>0,95</td>
              <td>1,11</td>
              <td>1,17</td>
              <td>1,24</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>40x20</td>
              <td>1,29</td>
              <td>1,51</td>
              <td>1,59</td>
              <td>1,74</td>
              <td>2,16</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>40x30</td>
              <td>1,51</td>
              <td>1,78</td>
              <td>1,88</td>
              <td>2,03</td>
              <td>2,55</td>
              <td>3,30</td>
              <td>3,84</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>50x20</td>
              <td>1,51</td>
              <td>1,78</td>
              <td>1,88</td>
              <td>2,03</td>
              <td>2,55</td>
              <td>3,30</td>
              <td>3,84</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>50x30</td>
              <td>1,81</td>
              <td>2,04</td>
              <td>2,16</td>
              <td>2,38</td>
              <td>2,94</td>
              <td>3,49</td>
              <td>4,28</td>
              <td>4,86</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>50x40</td>
              <td>2,05</td>
              <td>2,31</td>
              <td>2,44</td>
              <td>2,7</td>
              <td>3,34</td>
              <td>3,96</td>
              <td>3,56</td>
              <td>5,49</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60x20</td>
              <td>1,81</td>
              <td>2,04</td>
              <td>2,16</td>
              <td>2,38</td>
              <td>3,03</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60x30</td>
              <td>2,05</td>
              <td>2.31</td>
              <td>2.44</td>
              <td>2,70</td>
              <td>2,34</td>
              <td>4,19</td>
              <td>4,83</td>
              <td>5,49</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60x40</td>
              <td>2,29</td>
              <td>2,58</td>
              <td>2,72</td>
              <td>3,01</td>
              <td>3,72</td>
              <td>4,28</td>
              <td>5,11</td>
              <td>5,51</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>70x50</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>3,65</td>
              <td>4.54</td>
              <td>5.51</td>
              <td>6.21</td>
              <td>7.11</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>80x40</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>3.29</td>
              <td>3,64</td>
              <td>4,50</td>
              <td>5,22</td>
              <td>6,21</td>
              <td>6,76</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>80x50</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4,90</td>
              <td>5.69</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>80x60</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>3.83</td>
              <td>4,26</td>
              <td>5,29</td>
              <td>6,16</td>
              <td>7,31</td>
              <td>8,02</td>
              <td>9,26</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100x40</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4,26</td>
              <td>5,29</td>
              <td>6,16</td>
              <td>7,73</td>
              <td>8,02</td>
              <td>9,26</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100x50</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>5.68</td>
              <td>6,63</td>
              <td>7.86</td>
              <td>8.65</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100x60</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4.86</td>
              <td>6,07</td>
              <td>7,10</td>
              <td>8,41</td>
              <td>9,28</td>
              <td>10,67</td>
              <td>11,30</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100x80</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>6,86</td>
              <td>8,04</td>
              <td>9,51</td>
              <td>10,50</td>
              <td>12,08</td>
              <td>12,80</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>120x40</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4.86</td>
              <td>6,07</td>
              <td>7,10</td>
              <td>8,41</td>
              <td>9,28</td>
              <td>10,67</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>120x60</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>6.71</td>
              <td>8,04</td>
              <td>9,51</td>
              <td>10,50</td>
              <td>12,08</td>
              <td>12,80</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>120x80</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>9,17</td>
              <td>10,61</td>
              <td>12,13</td>
              <td>13,49</td>
              <td>15,03</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>120x100</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>10,03</td>
              <td>11,70</td>
              <td>12,99</td>
              <td>14,91</td>
              <td>15,81</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>140x60</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>11,73</td>
              <td>13,49</td>
              <td>14,24</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>140x80</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>9,90</td>
              <td>11,70</td>
              <td>12,99</td>
              <td>14,91</td>
              <td>15,81</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>150x50</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>11.4</td>
              <td>13.3</td>
              <td>15,2</td>
              <td>17,03</td>
              <td>18,17</td>
              <td>21,45</td>
            </tr>
            <tr>
              <td>160x100</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>15,83</td>
              <td>17,73</td>
              <td>19,63</td>
              <td>23,36</td>
            </tr>
            <tr>
              <td>200x80</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>17,08</td>
              <td>19,5</td>
              <td>21,20</td>
              <td>25,25</td>
            </tr>
            <tr>
              <td>200x100</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>18,01</td>
              <td>20,56</td>
              <td>22,96</td>
              <td>26,16</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PravougaoneCevi
