import React from "react"

const CelicneCevi = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table className="table is-bordered is-fullwidth is-hoverable has-text-centered">
        <thead>
          <tr>
            <th className="is-link has-text-centered" colSpan={3}>
              Standard za kvalitet
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>EN 10027-1</td>
            <td>DIN 17100</td>
            <td>JUS C.B0.500</td>
          </tr>
          <tr>
            <td colSpan={3} className="is-link">
              Oznaka vrste čelika
            </td>
          </tr>
          <tr>
            <td>S 235 JRG2</td>
            <td>RSt 37-2</td>
            <td>Č 0361</td>
          </tr>
          <tr>
            <td>S 235 JO</td>
            <td>St 37-3 U</td>
            <td>Č 0362</td>
          </tr>
          <tr>
            <td>S 235 J2G3</td>
            <td>St 37-3 N</td>
            <td>Č 0363</td>
          </tr>
          <tr>
            <td>S275 JR</td>
            <td>St 44-2</td>
            <td>Č 0451</td>
          </tr>
          <tr>
            <td>S 275 JO</td>
            <td>St 44-3 U</td>
            <td>Č 0452</td>
          </tr>
          <tr>
            <td>S 275 J2G3</td>
            <td>St 44-3 N</td>
            <td>Č 0453</td>
          </tr>
          <tr>
            <td>S 255 JR</td>
            <td>&nbsp;</td>
            <td>Č 0561</td>
          </tr>
          <tr>
            <td>S 355 JO</td>
            <td>St 52-3 U</td>
            <td>Č 0562</td>
          </tr>
          <tr>
            <td>S 355 J2G3</td>
            <td>St 52-3 N</td>
            <td>Č 0563</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CelicneCevi
