import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import OkrugleCevi from "../../components/tables/OkrugleCevi"
import CelicneCevi from "../../components/tables/CelicneCevi"
import KvadratneCevi from "../../components/tables/KvadratneCevi"
import PravougaoneCevi from "../../components/tables/PravougaoneCevi"

import Img1 from "../../images/savne2.jpg"
import Img2 from "../../images/savne1.jpg"
import Img3 from "../../images/savne3.jpg"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Head from "../../components/head"

const celicneCevi = () => {
  const title = "Šavne cevi"
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]

  return (
    <Layout>
      <Head title="Šavne Cevi" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>ŠAVNE CEVI</strong> uzdužno elektrootporno zavarene cevi
                hladno oblikovanog osnovnog materijala okruglog, kvadratnog i
                pravougaonog preseka izrađene su od toplovaljane ili
                hladnovaljane trake kvaliteta od konstrukcionih čelika pogodnih
                za hladno oblikovanje, nalaze primenu za konstrukcije u
                građevinarstvu i mašinogradnji, poljomehanizaciji i
                automobilskoj industriji.
              </p>
              <br />
              <p>
                <strong>Asortiman:</strong>
                <br />
                Šavne cevi, za konstrukcije isporučuju se uobičajeno u
                kvalitetima opšte konstrukcionih čelika S235JR i DD11. Poprečni
                presek čeličnih šavnih cevi karakteristike preseka su date na
                osnovu nazivnih spoljašnjih mera i debljina zida.
              </p>
              <div className="columns is-multiline"></div>
            </div>
          </div>
          <section className="tab-section">
            <Tabs style={{ width: "100%" }}>
              <TabList className="tabs is-centered is-boxed is-medium ">
                <ul className="is-size-4 is-size-7-mobile tabs-mobile">
                  <Tab>
                    <a>Hemijski sastav</a>
                  </Tab>
                  <Tab>
                    <a>Okrugle cevi</a>
                  </Tab>
                  <Tab>
                    <a>Kvadratne cevi</a>
                  </Tab>
                  <Tab>
                    <a>Pravougaone cevi</a>
                  </Tab>
                </ul>
              </TabList>
              <div>
                <TabPanel>
                  <CelicneCevi />
                </TabPanel>
                <TabPanel>
                  <OkrugleCevi />
                </TabPanel>
                <TabPanel>
                  <KvadratneCevi />
                </TabPanel>
                <TabPanel>
                  <PravougaoneCevi />
                </TabPanel>
              </div>
            </Tabs>
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default celicneCevi
