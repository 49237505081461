import React from "react"

const KvadratneCevi = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Isporučuju se prema standardu SRPS EN 10219-1 šavne cevi, hladno
          oblikovane, okruglog, kvadratnog i pravougaonog preseka za
          konstrukcije, oblik i mere u dimenzijama:
          <br /> - stranice kvadrata od 13x13 mm do 160x160 mm i debljine zida
          1,5 - 6 mm prema tabeli:
        </p>
      </div>
      <div className="table-scroll">
        <table className="table is-bordered is-hoverable is-fullwidth">
          <tbody>
            <tr>
              <th rowSpan="3" className="has-text-centered">
                <br />
                <br />
                Dimezije stranica (mm)
              </th>
              <th colSpan={15} className="has-text-centered is-link">
                Debljina zida (mm)
              </th>
            </tr>
            <tr>
              <td>1,5</td>
              <td>1,8</td>
              <td>2</td>
              <td>2,5</td>
              <td>3</td>
              <td>3,5</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
            </tr>

            <tr>
              <th colSpan={15} className="has-text-centered is-link">
                Produžena masa (kg/m)*
              </th>
            </tr>
            <tr>
              <td>13x13</td>
              <td>0,52</td>
              <td>0,63</td>
              <td>0,662</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>15x15</td>
              <td>0,62</td>
              <td>0,75</td>
              <td>0,789</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>16x16</td>
              <td>0,66</td>
              <td>0,8</td>
              <td>0,842</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>18x18</td>
              <td>0,74</td>
              <td>0,92</td>
              <td>0,947</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>20x20</td>
              <td>0,84</td>
              <td>1,03</td>
              <td>1,10</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>25x25</td>
              <td>1,84</td>
              <td>1,31</td>
              <td>1,45</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>30x30</td>
              <td>1,29</td>
              <td>1,59</td>
              <td>1,38</td>
              <td>2,16</td>
              <td>2,54</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>35x35</td>
              <td>1,51</td>
              <td>1,88</td>
              <td>2,03</td>
              <td>2,55</td>
              <td>3,01</td>
              <td>3,84</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>40x40</td>
              <td>1,81</td>
              <td>2,16</td>
              <td>2,38</td>
              <td>2,49</td>
              <td>3,49</td>
              <td>4,01</td>
              <td>4,52</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>50x50</td>
              <td>2,28</td>
              <td>2,72</td>
              <td>3,01</td>
              <td>3,72</td>
              <td>4,28</td>
              <td>5,11</td>
              <td>5,51</td>
              <td>6,39</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60x60</td>
              <td>2,76</td>
              <td>3,29</td>
              <td>3,65</td>
              <td>4,50</td>
              <td>5,22</td>
              <td>6,21</td>
              <td>6,76</td>
              <td>8,13</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>70x70</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4,26</td>
              <td>4,29</td>
              <td>6,16</td>
              <td>7,31</td>
              <td>8,02</td>
              <td>9,70</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>80x80</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>4.86</td>
              <td>6,07</td>
              <td>7,10</td>
              <td>8,41</td>
              <td>9,28</td>
              <td>11,30</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>90x90</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>6.71</td>
              <td>8,04</td>
              <td>9,51</td>
              <td>10,5</td>
              <td>12,80</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100x100</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>7.49</td>
              <td>8,99</td>
              <td>10,61</td>
              <td>11,8</td>
              <td>14,40</td>
              <td>17,71</td>
            </tr>
            <tr>
              <td>110x110</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>9,9</td>
              <td>11,7</td>
              <td>12,99</td>
              <td>15,81</td>
              <td>18,62</td>
            </tr>
            <tr>
              <td>120x120</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>10,84</td>
              <td>12,8</td>
              <td>14,25</td>
              <td>17,38</td>
              <td>20,51</td>
            </tr>
            <tr>
              <td>130x130</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>15,82</td>
              <td>19,62</td>
              <td>23,36</td>
            </tr>
            <tr>
              <td>140x140</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>17,19</td>
              <td>21,36</td>
              <td>25,49</td>
            </tr>
            <tr>
              <td>150x150</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>18,01</td>
              <td>22,09</td>
              <td>26,16</td>
            </tr>
            <tr>
              <td>160x160</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>19,4</td>
              <td>23,76</td>
              <td>28,04</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default KvadratneCevi
